<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-01-14 11:24:25
 * @LastEditTime: 2022-01-14 11:24:26
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper fullscreen">
    <div ref="sectionWrapper" class="content-view section-wrapper">
      <section class="container-403">
        <div class="wrapper-403">
          <div class="tip-image-403"></div>
          <p class="tip-text">
            非常抱歉! 您没有系统的访问权限, 请联系公司账号管理员！
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-403"
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/error.scss";
</style>
